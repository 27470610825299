@import 
url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  font-family: "Poppins", sans-serif;
  color-scheme: dark;
}

html {
  scroll-behavior: smooth;
}

.desktop-link {
  display: none;
}

.mobile-link {
  display: block;
}

@media (min-width: 1024px) {
  /* Hide the mobile link on larger screens (desktops) */
  .mobile-link {
    display: none;
  }

  /* Show the unclickable text on larger screens (desktops) */
  .desktop-link {
    display: block;
  }
}


@keyframes marquee {
  0% {
    transform: translateX(0%); /* Start with the first testimonial visible */
  }
  100% {
    transform: translateX(-100%); /* Move so that the last testimonial is off-screen */
  }
}

.animate-marquee {
  animation: marquee 40s linear infinite; /* Adjust duration as needed */
  display: flex;
  width: calc(200%); /* Double the width to fit both the original and duplicated content */
}






